<template>
   <div class="box box-primary">
            <div class="box-header with-border">
                <i class="fa fa-object-group"></i>
                <h3 class="box-title text-right">Department Configuration</h3>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-md-12 mbottom-15">
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group has-error">
                                <label class="control-label text-left">Department Name</label><input id="name" type="text" class="form-control"><!--v-if-->
                            </div>
                        </div>
                        <!--v-if-->
                        </div>
                        <div class="row">
                        <div class="col-md-12 mbottom-15">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="box box-primary">
                                    <div class="box-header with-border">
                                        <h3 class="box-title text-right">Segments</h3>
                                    </div>
                                    <div class="box-body">
                                        <div class="form-group">
                                            <div class="checkbox" iservice-id-prefix="'Segment'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="5"> _iService</label></div>
                                            <div class="checkbox" iservice-id-prefix="'Segment'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="2"> 1to1service</label></div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="box box-primary">
                                    <div class="box-header with-border">
                                        <h3 class="box-title text-right">Skills</h3>
                                    </div>
                                    <div class="box-body">
                                        <div class="form-group">
                                            <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="17"> Build Tracking</label></div>
                                            <div class="checkbox" iservice-id-prefix="'Skill'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="5"> critical</label></div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-primary margin-r-5">Create</button>
                            <button id="Cancel" class="btn btn-link">Cancel</button>
                        </div>
                        <div class="col-md-10 admin-error">
                            <!--v-if-->
                        </div>
                        <div class="clearfix"></div>
                        
                        </div>
                    </div>
                </div>
            </div>
            </div>

   
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import NewDepartmentPropertyForm from '../components/NewDepartmentPropertyForm.vue';
import DepartmentDetail from '../components/DepartmentDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Department Settings',
   components: {
    Breadcrumb,
    iServiceSwitch,
    Multiselect,
    NewDepartmentPropertyForm,
    Toggle,
    Multiselect,
    DepartmentDetail
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showDepartmentDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showDepartmentDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showDepartmentDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showDepartmentDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
